import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { environment } from '@environments/environment'
import { IAuthorization, MenuResponse } from '@lib/definitions/sidebar-item.definition'
import { AppStore } from '@lib/store/AppStore'
import { map } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class AuthorizationService {
	authorization?: IAuthorization
	private readonly appStore = inject(AppStore)
	loading = false

	constructor(private readonly http: HttpClient) {}

	async getAuthorizationData(email?: string): Promise<void> {
		this.loading = true
		const headers = new HttpHeaders({
			'Transaction-Id': '1',
			Secuence: '1',
		})
		const f = JSON.parse(localStorage.getItem('userSession') || '')
		return new Promise((resolve, reject): void => {
			this.http
				// .get<MenuResponse>(`${environment.backendUrl}portal/connect365/api/v1/usuarios/menu/roles`, {
					.get<MenuResponse>(`${environment.backendUrl}portal/connect365/api/v1/usuarios/${f.email}/roles`, {
					headers,
				})
				.subscribe({
					next: (value): void => {
						if (!value) return alert('Credenciales incorrectas')
						this.authorization = value.responses.schema
						this.appStore.setUserSession({ rol: value.responses.schema.rol, names: value.responses.schema.names })
						this.loading = false
						resolve()
					},
					error: (error): void => {
						this.loading = false
						console.error(error)

						reject(error)
					},
				})
		})
	}
}
